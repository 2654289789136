import { Container } from "@mui/material";

import BreadCrumb from "../../components/BreadCrumb";

const Home = () => {
  return (
    <Container maxWidth="xl">
      <BreadCrumb />
      <div>Bienvenue sur la platorme opérations : POPS</div>
    </Container>
  );
};

export default Home;
