export type Id = {
  id: string;
};

export type RowDataGrid<T> = T & Id;

export enum SlugChoice {
  REPLACE = "replace",
  DELETE = "delete",
}
