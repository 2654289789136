import { getwelcomApi as api } from "./getwelcomApi";
export const addTagTypes = ["Account"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      accountControllerCreate: build.mutation<
        AccountControllerCreateApiResponse,
        AccountControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/account`,
          method: "POST",
          body: queryArg.createAccountDto,
        }),
        invalidatesTags: ["Account"],
      }),
      accountControllerFindAll: build.query<
        AccountControllerFindAllApiResponse,
        AccountControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/account`,
          params: {
            extentUser: queryArg.extentUser,
            extentHoster: queryArg.extentHoster,
          },
        }),
        providesTags: ["Account"],
      }),
      accountControllerFindOne: build.query<
        AccountControllerFindOneApiResponse,
        AccountControllerFindOneApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}`,
          params: {
            extentUser: queryArg.extentUser,
            extentHoster: queryArg.extentHoster,
          },
        }),
        providesTags: ["Account"],
      }),
      accountControllerUpdate: build.mutation<
        AccountControllerUpdateApiResponse,
        AccountControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.updateAccountDto,
        }),
        invalidatesTags: ["Account"],
      }),
      accountControllerCloseAccount: build.mutation<
        AccountControllerCloseAccountApiResponse,
        AccountControllerCloseAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Account"],
      }),
      accountControllerFindByHoster: build.query<
        AccountControllerFindByHosterApiResponse,
        AccountControllerFindByHosterApiArg
      >({
        query: (queryArg) => ({ url: `/account/byhoster/${queryArg.id}` }),
        providesTags: ["Account"],
      }),
      accountControllerAddHoster: build.mutation<
        AccountControllerAddHosterApiResponse,
        AccountControllerAddHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}/addhoster/${queryArg.hosterId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Account"],
      }),
      accountControllerAddUser: build.mutation<
        AccountControllerAddUserApiResponse,
        AccountControllerAddUserApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}/adduser/${queryArg.userId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Account"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type AccountControllerCreateApiResponse = unknown;
export type AccountControllerCreateApiArg = {
  createAccountDto: CreateAccountDto;
};
export type AccountControllerFindAllApiResponse = unknown;
export type AccountControllerFindAllApiArg = {
  extentUser: boolean;
  extentHoster: boolean;
};
export type AccountControllerFindOneApiResponse = unknown;
export type AccountControllerFindOneApiArg = {
  id: string;
  extentUser: boolean;
  extentHoster: boolean;
};
export type AccountControllerUpdateApiResponse = unknown;
export type AccountControllerUpdateApiArg = {
  id: string;
  updateAccountDto: UpdateAccountDto;
};
export type AccountControllerCloseAccountApiResponse = unknown;
export type AccountControllerCloseAccountApiArg = {
  id: string;
};
export type AccountControllerFindByHosterApiResponse = unknown;
export type AccountControllerFindByHosterApiArg = {
  id: string;
};
export type AccountControllerAddHosterApiResponse = unknown;
export type AccountControllerAddHosterApiArg = {
  id: string;
  hosterId: string;
};
export type AccountControllerAddUserApiResponse = unknown;
export type AccountControllerAddUserApiArg = {
  id: string;
  userId: string;
};
export type SchemaObjectId = {};
export type CreateAccountDto = {
  name: string;
  logo: string;
  hosters: SchemaObjectId[];
  users: SchemaObjectId[];
  limitHosters: number;
};
export type UpdateAccountDto = {
  name: string;
  logo: string;
  hosters: SchemaObjectId[];
  users: SchemaObjectId[];
  limitHosters: number;
};
export const {
  useAccountControllerCreateMutation,
  useAccountControllerFindAllQuery,
  useAccountControllerFindOneQuery,
  useAccountControllerUpdateMutation,
  useAccountControllerCloseAccountMutation,
  useAccountControllerFindByHosterQuery,
  useAccountControllerAddHosterMutation,
  useAccountControllerAddUserMutation,
} = injectedRtkApi;
