import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { Account } from "../../interfaces/Account";
import { RowDataGrid } from "../../interfaces/Types";
import { useAccountControllerFindAllQuery } from "../../reduxtk/account";
import BreadCrumb from "../../components/BreadCrumb";

const AccountsView = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useAccountControllerFindAllQuery({
    extentUser: false,
    extentHoster: false,
  });

  const prepareAccounts = (accounts: Account[]): RowDataGrid<Account>[] => {
    return accounts.map((row) => {
      return { ...row, id: row._id };
    });
  };

  const navigateToAccountDetails = ({ row }: GridRowParams) => {
    //console.log(`/accounts/${row._id}`)
    navigate(`/accounts/${row._id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom du groupe",
      width: 450,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: true,
    },
    {
      field: "contractStart",
      headerName: "Date de démarrage",
      type: "number",
      width: 175,
      editable: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.value.toLocaleString(),
    },
    {
      field: "limitHosters",
      headerName: "Max héberg.",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 70,
      // valueGetter: (params: GridValueGetterParams) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (rowParam: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modifier"
            className="textPrimary"
            onClick={() => navigateToAccountDetails(rowParam)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Cloturer"
            // onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isLoading) return <Spinner />;
  return (
    <Container maxWidth="xl">
      <BreadCrumb />
      <DataGrid
        rows={prepareAccounts(data as Account[])}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Container>
  );
};

export default AccountsView;
