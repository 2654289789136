import { SubscriptionStatus } from '../../interfaces/Hoster';
import { details } from '../common/commonDetails';
import { Offers } from '../types/offers.enum';

export class OfferType {
  static getOfferType(subscriptionStatus: SubscriptionStatus): Offers | null {
    if (!subscriptionStatus) return null;
    if (this.isCustomerEngagementOffer(subscriptionStatus)) return Offers.CustomerEngagement;
    else if (this.isCustomerPath(subscriptionStatus)) return Offers.CustomerPath;
    else if (this.isEssentialOffer(subscriptionStatus)) return Offers.Essential;
    else return Offers.Custom;
  }

  private static isCustomerPath(subscriptionStatus: SubscriptionStatus): boolean {
    const essential = [
      details.guest_app.externalId,
      details.crm_customer_path.externalId,
      details.satisfaction_surveys.externalId,
      details.police_record.externalId,
    ];
    return this.isOffer(subscriptionStatus, essential);
  }

  private static isEssentialOffer(subscriptionStatus: SubscriptionStatus): boolean {
    const essential = [
      details.guest_app.externalId
    ];
    return this.isOffer(subscriptionStatus, essential);
  }

  private static isCustomerEngagementOffer(subscriptionStatus: SubscriptionStatus): boolean {
    const customerEngagement = [
      details.police_record.externalId,
      details.guest_app.externalId,
      details.crm_customer_path.externalId,
      details.satisfaction_surveys.externalId,
      details.ereputation.externalId,
      details.crm_customer_engagement.externalId,
    ];
    return this.isOffer(subscriptionStatus, customerEngagement);
  }

  private static isOffer(subscriptionStatus: SubscriptionStatus, offer: string[]): boolean {
    const keys = Object.keys(subscriptionStatus).filter((key) => key !== "_id") as [keyof SubscriptionStatus];

    for (const key of keys) {
      if (key === "isVOIPSubscribed" || key === "isAdditionalSellsSubscribed" || key === "isPoliceRecordSubscribed") continue;

      if (offer.includes(key) && !subscriptionStatus[key]) return false;
      if (!offer.includes(key) && subscriptionStatus[key]) return false;
    }

    return true;
  }

  static getSubscriptionStatusFromOfferType(
    offerType: Offers,
    origin: SubscriptionStatus | undefined
  ): SubscriptionStatus | undefined {
    if (!origin) return undefined;
    const newSubscriptionStatus: SubscriptionStatus = {
      ...origin,
      isGuestAppSubscribed: false,
      isVOIPSubscribed: false,
      isPoliceRecordSubscribed: false,
      isAdditionalSellsSubscribed: false,
      isCRMCustomerPathSubscribed: false,
      isSatisfactionSurveysSubscribed: false,
      isEreputationSubscribed: false,
      isCRMCustomerEngagementSubscribed: false,
    };
    if (offerType === Offers.Essential) newSubscriptionStatus.isGuestAppSubscribed = true;
    else if (offerType === Offers.CustomerPath) {
      newSubscriptionStatus.isPoliceRecordSubscribed = true;
      newSubscriptionStatus.isGuestAppSubscribed = true;
      newSubscriptionStatus.isCRMCustomerPathSubscribed = true;
      newSubscriptionStatus.isSatisfactionSurveysSubscribed = true;
    }
    else if (offerType === Offers.CustomerEngagement) {
      newSubscriptionStatus.isPoliceRecordSubscribed = true;
      newSubscriptionStatus.isGuestAppSubscribed = true;
      newSubscriptionStatus.isCRMCustomerPathSubscribed = true;
      newSubscriptionStatus.isSatisfactionSurveysSubscribed = true;
      newSubscriptionStatus.isEreputationSubscribed = true;
      newSubscriptionStatus.isCRMCustomerEngagementSubscribed = true;
    }
    return newSubscriptionStatus;
  }
}
