import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

export default function BreadCrumb() {
  const location = useLocation();

  const breadcrumbs = {
    home: (
      <Link underline="hover" key="1" color="inherit" href="/">
        POPS
      </Link>
    ),
    createHoster: (
      <Link underline="hover" key="2-1" color="inherit" href="/create">
        Créer un hébergement
      </Link>
    ),
    editAccount: (
      <Link underline="hover" key="2-2" color="inherit">
        Editer un groupe
      </Link>
    ),
    editHoster: (
      <Link underline="hover" key="2-3" color="inherit">
        Editer une plateforme
      </Link>
    ),
    listAccounts: (
      <Link underline="hover" key="2-3" color="inherit" href="/accounts">
        Liste des groupes
      </Link>
    ),
    listHosters: (
      <Link underline="hover" key="2-3" color="inherit" href="/hosters">
        Liste des plateformes
      </Link>
    ),
  };

  const getBreadcrumbs = (location: string) => {
    const isLocation = (regexp: string): boolean =>
      RegExp(regexp).test(location);
    switch (true) {
      case isLocation("/create"):
        return [breadcrumbs.home, breadcrumbs.createHoster];
      case isLocation("/accounts[/]{0,1}$"):
        return [breadcrumbs.home, breadcrumbs.listAccounts];
      case isLocation("/accounts/*?"):
        return [
          breadcrumbs.home,
          breadcrumbs.listAccounts,
          breadcrumbs.editAccount,
        ];
      case isLocation("/hosters[/]{0,1}$"):
        return [breadcrumbs.home, breadcrumbs.listHosters];
      case isLocation("/hosters/*?"):
        return [
          breadcrumbs.home,
          breadcrumbs.listHosters,
          breadcrumbs.editHoster,
        ];
      case isLocation("/"):
        return [breadcrumbs.home];
      default:
        return [];
    }
  };

  return (
    <Box
      sx={{
        padding: "10px",
        borderBottom: "1px solid grey",
      }}
    >
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {getBreadcrumbs(location.pathname)}
        </Breadcrumbs>
      </Stack>
    </Box>
  );
}
