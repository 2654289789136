import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import keycloak from "../Keycloak";
import PrivateRoute from "../auth/PrivateRoute";
import MenuAppBar from "../components/MenuAppBar";
import AccountEditView from "../views/accounts/AccountEditView";
import AccountsView from "../views/accounts/AccountsView";
import CreateView from "../views/create/CreateView";
import Home from "../views/home/Home";
import HostersEditView from "../views/hosters/HostersEditView";
import HostersView from "../views/hosters/HostersView";

const Router = () => {
  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak}>
        <React.StrictMode>
          {/* <NonLoggedMenu /> */}
          <BrowserRouter>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <MenuAppBar />
              <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/accounts"
                    element={
                      <PrivateRoute>
                        <AccountsView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/accounts/:accountId"
                    element={
                      <PrivateRoute>
                        <AccountEditView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/hosters"
                    element={
                      <PrivateRoute>
                        <HostersView />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/hosters/:hosterId"
                    element={
                      <PrivateRoute>
                        <HostersEditView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create"
                    element={
                      <PrivateRoute>
                        <CreateView />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </Box>
            </Box>
          </BrowserRouter>
        </React.StrictMode>
      </ReactKeycloakProvider>
    </div>
  );
};

export default Router;
