import { getwelcomApi as api } from "./getwelcomApi";
export const addTagTypes = ["Account", "User"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      accountControllerAddUser: build.mutation<
        AccountControllerAddUserApiResponse,
        AccountControllerAddUserApiArg
      >({
        query: (queryArg) => ({
          url: `/account/${queryArg.id}/adduser/${queryArg.userId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Account"],
      }),
      userControllerCreate: build.mutation<
        UserControllerCreateApiResponse,
        UserControllerCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/user`,
          method: "POST",
          body: queryArg.createUserDto,
        }),
        invalidatesTags: ["User"],
      }),
      userControllerFindAll: build.query<
        UserControllerFindAllApiResponse,
        UserControllerFindAllApiArg
      >({
        query: (queryArg) => ({
          url: `/user`,
          params: {
            from: queryArg["from"],
            limit: queryArg.limit,
            email: queryArg.email,
            extentHoster: queryArg.extentHoster,
          },
        }),
        providesTags: ["User"],
      }),
      userControllerFindOne: build.query<
        UserControllerFindOneApiResponse,
        UserControllerFindOneApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}`,
          params: {
            from: queryArg["from"],
            limit: queryArg.limit,
            email: queryArg.email,
            extentHoster: queryArg.extentHoster,
          },
        }),
        providesTags: ["User"],
      }),
      userControllerUpdate: build.mutation<
        UserControllerUpdateApiResponse,
        UserControllerUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}`,
          method: "PATCH",
          body: queryArg.updateUserDto,
        }),
        invalidatesTags: ["User"],
      }),
      userControllerDelete: build.mutation<
        UserControllerDeleteApiResponse,
        UserControllerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["User"],
      }),
      userControllerFindByUser: build.query<
        UserControllerFindByUserApiResponse,
        UserControllerFindByUserApiArg
      >({
        query: (queryArg) => ({ url: `/user/${queryArg.userId}/account` }),
        providesTags: ["User"],
      }),
      userControllerFindHoster: build.query<
        UserControllerFindHosterApiResponse,
        UserControllerFindHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.userId}/hoster/${queryArg.hosterIndex}`,
        }),
        providesTags: ["User"],
      }),
      userControllerAddHoster: build.mutation<
        UserControllerAddHosterApiResponse,
        UserControllerAddHosterApiArg
      >({
        query: (queryArg) => ({
          url: `/user/${queryArg.id}/addhoster/${queryArg.hosterId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["User"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type AccountControllerAddUserApiResponse = unknown;
export type AccountControllerAddUserApiArg = {
  id: string;
  userId: string;
};
export type UserControllerCreateApiResponse = unknown;
export type UserControllerCreateApiArg = {
  createUserDto: CreateUserDto;
};
export type UserControllerFindAllApiResponse = unknown;
export type UserControllerFindAllApiArg = {
  from?: number;
  limit?: number;
  email?: string;
  extentHoster?: boolean;
};
export type UserControllerFindOneApiResponse = unknown;
export type UserControllerFindOneApiArg = {
  userId: string;
  from?: number;
  limit?: number;
  email?: string;
  extentHoster?: boolean;
};
export type UserControllerUpdateApiResponse = unknown;
export type UserControllerUpdateApiArg = {
  userId: string;
  updateUserDto: UpdateUserDto;
};
export type UserControllerDeleteApiResponse = unknown;
export type UserControllerDeleteApiArg = {
  userId: string;
};
export type UserControllerFindByUserApiResponse = unknown;
export type UserControllerFindByUserApiArg = {
  userId: string;
};
export type UserControllerFindHosterApiResponse = unknown;
export type UserControllerFindHosterApiArg = {
  userId: string;
  hosterIndex: string;
};
export type UserControllerAddHosterApiResponse = unknown;
export type UserControllerAddHosterApiArg = {
  id: string;
  hosterId: string;
};
export type CreateUserDto = {
  hoster: string[];
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  profil: string;
};
export type UpdateUserDto = {
  hoster: string[];
  firstname: string;
  lastname: string;
  password: string;
  profil: string;
};
export const {
  useAccountControllerAddUserMutation,
  useUserControllerCreateMutation,
  useUserControllerFindAllQuery,
  useUserControllerFindOneQuery,
  useUserControllerUpdateMutation,
  useUserControllerDeleteMutation,
  useUserControllerFindByUserQuery,
  useUserControllerFindHosterQuery,
  useUserControllerAddHosterMutation,
} = injectedRtkApi;
