import { Chip, Divider } from "@mui/material";
import { useAccountControllerFindAllQuery } from "../../reduxtk/account";
import { Account } from "../../interfaces/Account";
import { SelectElement } from "react-hook-form-mui";
import { useEffect } from "react";

interface DuplicateAccountFormProps {
  display: boolean;
  loadingfn: (value: Boolean) => any;
}

const DuplicateAccountForm = ({
  display,
  loadingfn,
}: DuplicateAccountFormProps) => {
  const {
    data: accounts,
    isLoading: isLoadingAccount,
    isFetching,
  } = useAccountControllerFindAllQuery<{
    data: Account[];
    isFetching: Boolean;
    isLoading: Boolean;
    isError: Boolean;
    isSuccess: Boolean;
  }>({
    extentUser: false,
    extentHoster: false,
  });

  const getAccountsList = () => {
    return accounts
      ?.map((account: any) => {
        return {
          id: account._id,
          label: `${account.name}`,
        };
      })
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
  };

  useEffect(() => {
    loadingfn(isLoadingAccount);
  }, [accounts, isLoadingAccount, isFetching, loadingfn]);

  if (isLoadingAccount) return <>CHARGEMENT</>;

  return (
    <>
      <Divider>
        <Chip label="Groupe existant" />
      </Divider>
      <SelectElement
        name="accountToDuplicate"
        label="Nom du groupe"
        options={getAccountsList()}
        disabled={!display}
        sx={{
          backgroundColor: !display ? "#eeeeee" : "white",
        }}
      />
    </>
  );
};

export default DuplicateAccountForm;
