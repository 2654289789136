import React from "react";
import { HosterDto, useHosterControllerGetDefaultPropertiesQuery } from "../../reduxtk/hoster";
import "./HosterProperties.scss";
import { DebounceInput } from 'react-debounce-input';

interface HosterProps {
    hoster: Partial<HosterDto>,
    updateProperty: (key: string, value: string) => void
}

const HosterProperties: React.FC<HosterProps> = (props) => {
    const { currentData } = useHosterControllerGetDefaultPropertiesQuery();

    const propertyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let key = e.target.name;
        let value = e.target.value;
        console.log(key, value);

        props.updateProperty(key, value);
    }

    return (
        <div className="hosterProperties">
            <table>
                <thead>
                    <tr>
                        <th scope="col">Paramètres</th>
                        <th scope="col">Valeur par défaut</th>
                        <th scope="col">Valeur surchargée</th>
                    </tr>
                </thead>
                <tbody>
                    {props.hoster.properties && Object.keys(props?.hoster?.properties).map((key, index) => {
                        let defaultValue = currentData ? currentData[key] : 'N/A'
                        return (
                            <tr key={index}>
                                <td>{key}</td>
                                <td>
                                    {defaultValue}
                                </td>
                                <td>
                                    <DebounceInput debounceTimeout={1500} spellCheck={false} onChange={propertyChange} style={{ padding: '10px' }} name={key} value={(props.hoster.properties?.[key] ?? '')} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default HosterProperties;