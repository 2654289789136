import keycloak from "../Keycloak"

/*import type { AccessToken } from "simple-oauth2";
import { ResourceOwnerPassword } from "simple-oauth2";

var Url = require("url");

Url.URL = function(path: string, baseUrl: string) {
  return new URL(baseUrl + path);
}

// import urlPolyfill from "url-polyfill";

export async function getOAuthToken(): Promise<AccessToken> {
  const config = {
    client: {
      id: `${process.env.REACT_APP_CLIENT_ID}`,
      secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
    },
    auth: {
      tokenHost: `${process.env.REACT_APP_KC_HOST}`,
      tokenPath: `${process.env.REACT_APP_KC_HOST_ENDPOINT}`,
    },
    options: {
      bodyFormat: "form" as "form" | "json" | undefined,
      authorizationMethod: "body" as "body" | "header" | undefined,
    },
  };

  const client = new ResourceOwnerPassword(config);
  // console.log("client", client);
  const tokenParams = {
    username: `${process.env.REACT_APP_KC_USERNAME}`,
    password: `${process.env.REACT_APP_KC_PASSWORD}`,
  };
  // console.log("tokenParams", tokenParams);
  try {
    // console.log("before accessToken");
    const httpOptions = {
      baseUrl: `${process.env.REACT_APP_KC_HOST}`,
    }
    const accessToken = await client.getToken(tokenParams, httpOptions);
    // console.log("after accessToken", accessToken);

    return accessToken;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
let tokens = null as AccessToken | null;
export async function getValidToken(): Promise<any> {
  if (tokens === null || tokens === undefined) {
    tokens = await getOAuthToken();
    console.log("tokens", tokens);
  } else if (tokens.expired()) {
    try {
      const refreshParams = {
        scope: "email profile",
      };
      tokens = await tokens.refresh(refreshParams);
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  return tokens.token.access_token;
}*/


export async function getValidToken(): Promise<any> {
  // If the token expires within 5 seconds, the token is refreshed.
  return keycloak.updateToken(5)
  .then((refreshed) => {
    return keycloak.token;})
  .catch(() => {alert('Failed to refresh the token, or the session has expired');});
}