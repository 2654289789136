import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Account } from "../../interfaces/Account";
import { Hoster } from "../../interfaces/Hoster";
import { RowDataGrid } from "../../interfaces/Types";
import { useAccountControllerFindAllQuery } from "../../reduxtk/account";
import { useHostersControllerFindAllQuery } from "../../reduxtk/hoster";
import Spinner from "../../components/Spinner";
import BreadCrumb from "../../components/BreadCrumb";

interface HostersResponse {
  data: Hoster[];
}

interface HosterWithAccount extends Hoster {
  accountName?: string;
}

const HostersView = () => {
  const navigate = useNavigate();

  const { data: hosters, isLoading: isLoadingHosters } =
    useHostersControllerFindAllQuery({});
  const { data: accounts } = useAccountControllerFindAllQuery({
    extentUser: false,
    extentHoster: false,
  });

  const prepareHosters = (
    hosters: Hoster[],
    accounts: Account[]
  ): RowDataGrid<HosterWithAccount>[] => {
    // console.log(hosters);
    // console.log(accounts);
    return hosters.map((row) => {
      const account = accounts?.find(
        (account) =>
          account.hosters.find((h) => h._id === row._id) !== undefined
      );
      return { ...row, accountName: account?.name, id: row._id };
    });
  };

  const navigateToHosterDetails = ({ row }: GridRowParams) => {
    //console.log(`/accounts/${row._id}`)
    navigate(`/hosters/${row._id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom de la vitrine",
      width: 450,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    // },
    {
      field: "slug",
      headerName: "Slug",
      width: 175,
      valueGetter: (params: GridValueGetterParams) =>
        params.value?.toLocaleString(),
    },
    {
      field: "accountName",
      headerName: "Groupe",
      width: 175,
      valueGetter: (params: GridValueGetterParams) =>
        params.value?.toLocaleString(),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (rowParam: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modifier"
            className="textPrimary"
            onClick={() => navigateToHosterDetails(rowParam)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Cloturer"
            // onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isLoadingHosters) return <Spinner />;
  return (
    <Container maxWidth="xl">
      <BreadCrumb />
      <DataGrid
        rows={prepareHosters(
          (hosters as unknown as HostersResponse).data,
          accounts as Account[]
        )}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Container>
  );
};

export default HostersView;
