import { Box, Button, Container, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/BreadCrumb";
import { Account } from "../../interfaces/Account";
import {
  UpdateAccountDto,
  useAccountControllerFindOneQuery,
  useAccountControllerUpdateMutation,
} from "../../reduxtk/account";
import AccountHostersTable from "./AccountHostersTable";

const AccountEditView = () => {
  let params = useParams();
  const accountId = params.accountId as string;
  const initialAccount: Account = {
    name: "",
    logo: "",
    limitHosters: 0,
    hosters: [],
    users: [],
    _id: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    contractStart: new Date(),
    status: "",
  };

  const { currentData } = useAccountControllerFindOneQuery({
    id: accountId,
    extentUser: false,
    extentHoster: true,
  });
  const [updateAccount, result] = useAccountControllerUpdateMutation({
    fixedCacheKey: "shared-update-post",
  });

  const [account, setAccount] = useState<Account>(initialAccount);

  const setState = (key: string, value: any) => {
    setAccount({ ...account, [key]: value });
  };

  useEffect(() => {
    if (currentData) {
      setAccount(currentData as Account);
    }
  }, [currentData]);

  const setLimitHosters = (elem: any) => {
    if (parseInt(elem.target.value) >= -1) {
      setState("limitHosters", elem.target.value);
    }
  };

  const onSave = () => {
    const limitHosters = Number(account.limitHosters);
    const updateAccountData: UpdateAccountDto = {
      name: account.name,
      logo: account.logo,
      limitHosters,
      hosters: (currentData as Account).hosters.map((h) => h._id.toString()),
      users: (currentData as Account).users,
    };
    toast.promise(
      updateAccount({ id: accountId, updateAccountDto: updateAccountData }),
      {
        pending: "Mise à jour en cours...",
        success: "Opération réalisée avec succès 👌",
        error: "L'opération a échoué 🤯",
      }
    );
  };

  console.log(result, "RESULT");

  return (
    <Container maxWidth="xl">
      <BreadCrumb />
      {currentData ? (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "70ch" },
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="outlined-required"
            label="Nom"
            value={account.name}
            onChange={(e) => setState("name", e.target.value)}
          />

          <TextField
            required
            id="outlined-required"
            label="Logo"
            value={account.logo}
            onChange={(e) => setState("logo", e.target.value)}
          />

          <TextField
            required
            id="outlined-required"
            label="Max hébergements"
            type="number"
            onChange={(e) => setLimitHosters(e)}
            value={account.limitHosters}
            style={{ width: "20ch" }}
          />
          <AccountHostersTable hosters={(currentData as Account).hosters} />
        </Box>
      ) : (
        "Error: Account undefined."
      )}
      <Container>
        <Button variant="contained" onClick={onSave} sx={{ marginTop: "20px" }}>
          Sauvegarder les changements
        </Button>
      </Container>
    </Container>
  );
};

export default AccountEditView;
