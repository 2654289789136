import { Box, Container } from "@mui/material";
import * as React from "react";
import SyncLoader from "react-spinners/SyncLoader";

export default function Spinner() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <SyncLoader color="#1976d2" />
        Chargement en cours ...
      </Box>
    </Container>
  );
}
