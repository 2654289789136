import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Hoster } from "../../interfaces/Hoster";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/*
const hostersHydrated = (
  accountHosters: string[],
  globalHosters: Hoster[]
): Hoster[] => {
  console.log(accountHosters, "accountHosters");
  console.log(globalHosters, "globalHosters");
  return accountHosters.map((hoster: string): Hoster => {
    const hydratedHoster = globalHosters.find(
      (globalHoster: Hoster): Hoster | undefined => {
        return globalHoster._id === hoster ? globalHoster : undefined;
      }
    );
    console.log(hydratedHoster);
    return hydratedHoster !== undefined
      ? hydratedHoster
      : ({ _id: hoster } as Hoster);
  });
};
*/
type AccountHostersTableProps = {
  hosters: Hoster[];
};

const AccountHostersTable = ({ hosters }: AccountHostersTableProps) => {
  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <span style={{ display: "block", paddingBottom: "10px" }}>
        Liste des hébergements rattachés à ce groupe :{" "}
      </span>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nom de l'hébergement</StyledTableCell>
            <StyledTableCell align="right">Slug</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hosters.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.slug}</StyledTableCell>
              <StyledTableCell align="right">{row.status}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountHostersTable;
