import { Box, Button, Chip, Container, Divider } from "@mui/material";
import { useState } from "react";
import {
  FieldError,
  FormContainer,
  SelectElement,
  SwitchElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/BreadCrumb";
import Spinner from "../../components/Spinner";
import { Hoster } from "../../interfaces/Hoster";
import { User } from "../../interfaces/User";
import {
  useAccountControllerAddUserMutation,
  useAccountControllerCreateMutation,
  // useAccountControllerUpdateMutation,
  // useAccountControllerFindAllQuery,
} from "../../reduxtk/account";
import {
  // useAccountControllerAddHosterMutation,
  useHosterControllerDuplicateMutation,
  useHostersControllerFindAllQuery,
} from "../../reduxtk/hoster";
import {
  useUserControllerCreateMutation,
  useUserControllerFindAllQuery,
} from "../../reduxtk/user";
import DuplicateAccountForm from "./DuplicateAccountForm";
import { Account } from "../../interfaces/Account";

interface HostersResponse {
  data: Hoster[];
}

interface AccountResponse {
  data: Account;
}
interface UsersResponse {
  data: User[];
}

// interface HosterResponse {
//   data: Hoster;
// }

interface UserResponse {
  data: User;
}

interface UsersResponse {
  data: User[];
}

interface ErrorResponse {
  data: {
    message: string;
  };
}

interface CreationForm {
  useExistingAccount: boolean;
  accountToDuplicate: string;
  accountName: string;
  accountLogo: string;
  accountLimitHosters: number;
  userEmail: string;
  userFirstname: string;
  userLastname: string;
  hosterToDuplicate: string;
  hosterName: string;
  hosterSlug: string;
  facturationID: string;
  isAutomaticBilling: boolean
}

const CreateView = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [useExistingAccount, setExistingAccount] = useState(true);
  const [isAutomaticBilling, setIsAutomaticBilling] = useState<boolean>(false)
  const [, setIsLoadingAccounts] = useState<Boolean>(false);
  const { data: hosters, isLoading: isLoadingHosters } =
    useHostersControllerFindAllQuery<{
      data: HostersResponse;
      isFetching: Boolean;
      isLoading: Boolean;
    }>({});

  // const { data: accounts } = useAccountControllerFindAllQuery<{
  //   data: Account[];
  // }>({
  //   extentUser: true,
  //   extentHoster: false,
  // });

  const { data: users } = useUserControllerFindAllQuery<{
    data: UsersResponse;
    isFetching: Boolean;
    isLoading: Boolean;
  }>({});

  const [duplicateHoster] = useHosterControllerDuplicateMutation({
    fixedCacheKey: "shared-update-post",
  });
  const [createUser] = useUserControllerCreateMutation({
    fixedCacheKey: "shared-update-post",
  });
  const [createAccount] = useAccountControllerCreateMutation({
    fixedCacheKey: "shared-update-post",
  });
  // const [updateAccount] = useAccountControllerUpdateMutation({
  //   fixedCacheKey: "shared-update-post",
  // });
  // const [addHosterToAccount] = useAccountControllerAddHosterMutation({});
  const [addUserToAccount] = useAccountControllerAddUserMutation({});

  const loadingAccountCallback = (value: Boolean) => {
    console.log(value, "loading account");
    setIsLoadingAccounts(value);
  };
  // Declaration of the form
  const formContext = useForm<CreationForm>({
    defaultValues: {
      useExistingAccount: true,
      accountToDuplicate: "",
      accountName: "",
      accountLogo: "",
      accountLimitHosters: 1,
      userEmail: "",
      userFirstname: "",
      userLastname: "",
      hosterToDuplicate: "",
      hosterName: "",
      hosterSlug: "",
      facturationID: undefined,
      isAutomaticBilling: false
    },
  });
  const { handleSubmit, setValue, reset } = formContext;

  const getHostersList = () => {
    return hosters.data
      ?.map((hoster: any) => {
        return {
          id: hoster._id,
          label: `${hoster.slug} - ${hoster.name}`,
        };
      })
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        else if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
  };

  // const giveRightsForThisHosterToTheOwner = (
  //   hosterId: string,
  //   accountId: string
  // ) => {
  //   const account = accounts.filter((a) => a._id === accountId)[0];
  //   //get user details of an account

  //   // then find the props

  //   // then add hoster to owners
  // };

  const save = async (data: CreationForm) => {
    let newAccount: any = undefined;
    // Creation de l'account si c'est demandé
    if (!data.useExistingAccount) {
      console.log(data, "CREATE ACCOUNT");
      newAccount = await createAccount({
        createAccountDto: {
          name: `${data.hosterName} groupe`,
          logo: "",
          limitHosters: 1,
          hosters: [],
          users: [],
        },
      });
      console.log(newAccount, "account");

      if ("error" in newAccount) {
        return Promise.reject((newAccount.error as ErrorResponse).data.message);
      }
    }

    // Duplique le hoster
    let duplicationHoster = new Promise((resolve, reject) =>
      duplicateHoster({
        id: data.hosterToDuplicate,
        accountId: data.useExistingAccount
          ? data.accountToDuplicate
          : (newAccount as AccountResponse).data._id,
        duplicateHosterDto: {
          name: data.hosterName,
          address: {
            country: "France",
          },
          slug: data.hosterSlug,
          billingInfo: {
            clientId: data.facturationID,
            isAutomaticBilling: data.isAutomaticBilling
          }
        },
      })
        .then((res) => {
          if ("data" in res) {
            resolve(res);
          }
          if ("error" in res) {
            reject((res.error as ErrorResponse).data.message);
          }
        })
        .catch((err) => {
          reject(err);
        })
    );

    // Creation de l'utilisateur principale
    let creationUser = duplicationHoster.then((res: any) => {
      return new Promise((resolve, reject) =>
        createUser({
          createUserDto: {
            email: data.userEmail,
            firstname: data.userFirstname,
            lastname: data.userLastname,
            hoster: [res.data._id],
            password: "password",
            profil: "Propriétaire",
          },
        })
          .then((res) => {
            if ("data" in res) {
              resolve(res);
              reset({
                useExistingAccount: true,
                accountToDuplicate: "",
                accountName: "",
                accountLogo: "",
                accountLimitHosters: 1,
                userEmail: "",
                userFirstname: "",
                userLastname: "",
                hosterToDuplicate: "",
                hosterName: "",
                hosterSlug: "",
                facturationID: undefined,
                isAutomaticBilling: false
              })
            }
            if ("error" in res) {
              reject((res.error as ErrorResponse).data.message);
            }
          })
          .catch((err) => {
            reject(err);
          })
      );
    });

    return Promise.all([duplicationHoster, creationUser]).then((values) => {
      const [, userVal] = values;
      //const acc = accounts.find((e) => e._id === data.accountToDuplicate);

      // On rajoute le hoster au propriétaire du groupe
      if (data.useExistingAccount) {
        //accounts.
        console.log("ajout au propr du groupe");
      }
      return addUserToAccount({
        id: !data.useExistingAccount
          ? newAccount.data._id
          : data.accountToDuplicate,
        userId: (userVal as UserResponse).data._id,
      });
    });
  };

  const handleFormSubmit = (data: CreationForm) => {
    setIsSaving(true);
    console.log(data)
    toast.promise(
      save(data).then((e) => console.log(e)),
      {
        pending: {
          render() {
            return "Mise à jour en cours...";
          },
        },
        success: {
          render() {
            setIsSaving(false);
            return "Opération réalisée avec succès 👌";
          },
        },
        error: {
          render(err) {
            setIsSaving(false);
            return `L'opération a échoué 🤯 pour la raison suivante: "${err.data}"`;
          },
        },
      }
    );
  };

  const parseError = (error: FieldError) => {
    if (error.type === "validate") {
      return "Ce slug existe déjà";
    }
    return "Ce champ est obligatoire";
  };

  const validateSlug = (slug: string) => {
    return hosters.data.find((h) => h.slug === slug) === undefined;
  };

  const parseErrorUsers = (error: FieldError) => {
    if (error.type === "validate") {
      return "Cet utilisateur existe déjà";
    }
    return "Ce champ est obligatoire";
  };

  const validateUser = (email: string) => {
    return users.data.find((u) => u.email === email) === undefined;
  };

  if (isLoadingHosters) return <Spinner />;
  return (
    <Container sx={{ width: "100%" }} maxWidth="md">
      <BreadCrumb />

      <Box
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
      >
        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(handleFormSubmit)}
        >
          <SwitchElement
            name="useExistingAccount"
            label="La nouvelle plateforme doit appartenir à un groupe ?"
            checked={useExistingAccount}
            onClick={(e) => {
              if ((e.target as HTMLInputElement).name === "useExistingAccount")
                setExistingAccount(!useExistingAccount);
            }}
          />

          {/* Duplication d'un compte */}
          <DuplicateAccountForm
            display={useExistingAccount}
            loadingfn={loadingAccountCallback}
          />

          {/* Création d'un compte */}
          {/* <CreateAccountForm display={!useExistingAccount} /> */}

          {/* Création de l'utilisateur principal */}
          <Divider sx={{ mt: 5 }}>
            <Chip label="Utilisateur principal" />
          </Divider>
          <TextFieldElement
            name="userEmail"
            label="Email"
            required
            validation={{ validate: validateUser }}
            parseError={parseErrorUsers}
          />
          <TextFieldElement name="userFirstname" label="Prénom" required />
          <TextFieldElement name="userLastname" label="Nom" required />
          <Divider sx={{ mt: 5 }}>
            <Chip label="Facturation" />
          </Divider>
          <SwitchElement
            name="isAutomaticBilling"
            label="Facturation automatique"
            checked={isAutomaticBilling}
            onClick={() => {
              setIsAutomaticBilling(!isAutomaticBilling)
              setValue('isAutomaticBilling', !isAutomaticBilling)
            }}
          />
          {isAutomaticBilling && <TextFieldElement name="facturationID" label="ID Stripe du client" required />}

          <Divider sx={{ mt: 5 }}>
            <Chip label="Vitrine maitre (utilisée comme modéle)" />
          </Divider>
          <SelectElement
            name="hosterToDuplicate"
            label="Plateforme à dupliquer"
            options={getHostersList()}
            required
          />

          <Divider sx={{ mt: 5 }}>
            <Chip label="Information de la nouvelle vitrine" />
          </Divider>
          <TextFieldElement
            name="hosterName"
            label="Nom de la vitrine"
            required
          />
          <TextFieldElement
            name="hosterSlug"
            label="Slug"
            validation={{ validate: validateSlug }}
            parseError={parseError}
            required
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              paddingTop: "3ch",
            }}
          >
            <Button variant="contained" type={"submit"} disabled={isSaving}>
              Créer la vitrine et ses éléments associés
            </Button>
          </Box>
        </FormContainer>
      </Box>
    </Container>
  );
};

export default CreateView;
