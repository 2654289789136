import axios from 'axios';
import { Hoster } from '../interfaces/Hoster'; 
import { getValidToken } from '../auth/keycloack';

export class RedirectionAPI {
  private redirectRootURL: string;
  private apiURL: string;

  constructor() {
    let redirectURL = process.env.REACT_APP_REDIRECT_API_URL!;
    if (redirectURL[redirectURL.length - 1] === '/')
      redirectURL = redirectURL.slice(0, -1);
    this.apiURL = `${redirectURL}/api/redirect`;
    this.redirectRootURL = process.env.REACT_APP_REDIRECT_ROOT_URL!;
  }

  async createRedirectionUrl(hoster: Hoster, url?: string) {
    const urlObject = {
      slug: hoster.slug,
      url: url || `${this.redirectRootURL}/${hoster.slug}`,
    };

    const reqst = await axios.post(this.apiURL, urlObject, {
      headers: {
        Authorization: `Bearer ${await getValidToken()}`,
      },
    });
    return reqst.data;
  }


  async updateRedirectionUrl(hosterPrevSlug?: string, url?: string) {
    const urlObject = {
      url,
    };

    const reqst = await axios.put(`${this.apiURL}/${hosterPrevSlug}`, urlObject, {
      headers: { Authorization: `Bearer ${await getValidToken()}` },
    });
    return reqst.data;
  }

  async deleteRedirectionUrl(hosterPrevSlug?: string) {
    const reqst = await axios.delete(`${this.apiURL}/${hosterPrevSlug}`, {
      headers: { Authorization: `Bearer ${await getValidToken()}` },
    });
    return reqst.data;
  }
}
