import {  customDetails, customerPathDetails, engagementCustomerDetails, essentialDetails } from './common/commonDetails';
import { IOffer } from './interfaces/offer';
import { Offers } from './types/offers.enum';

export const offersGWM: IOffer[] = [
  {
    id: Offers.Essential,
    name: Offers.Essential,
    description: `Offre ${Offers.Essential}`,
    details: [...essentialDetails],
  },
  {
    id: Offers.CustomerPath,
    name: Offers.CustomerPath,
    description: `Offre ${Offers.CustomerPath}`,
    details: [...customerPathDetails],
  },
  {
    id: Offers.CustomerEngagement,
    name: Offers.CustomerEngagement,
    description: `Offre ${Offers.CustomerEngagement}`,
    details: [...engagementCustomerDetails],
  },
  {
    id: Offers.Custom,
    name: Offers.Custom,
    description: `Offre ${Offers.Custom}`,
    details: [...customDetails],
  },
];
